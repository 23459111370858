import { useState } from "react";
import { Layout, Menu, MenuProps } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import { FieldBinaryOutlined } from "@ant-design/icons";

type MenuItem = Required<MenuProps>["items"][number];

const items: MenuItem[] = [
  {
    key: "/",
    label: "UUID Generator",
    icon: <FieldBinaryOutlined />,
  },
];

export default function SideBar({ children }: { children: React.ReactNode }) {
  const [collapsed, setCollapsed] = useState(true);

  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  return (
    <Layout hasSider>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          insetInlineStart: 0,
          top: 0,
          bottom: 0,
          scrollbarWidth: "thin",
          scrollbarColor: "unset",
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          defaultSelectedKeys={["/"]}
          mode="inline"
          items={items}
          onClick={onClick}
        />
      </Sider>
      <Layout style={{ marginInlineStart: collapsed ? 80 : 200 }}>
        <Content>
          <div
            style={{
              padding: 24,
              minHeight: "100vh",
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

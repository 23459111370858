import { Button, Card, Flex, InputNumber, Tooltip, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Adsense } from "@ctrl/react-adsense";

const { Title, Paragraph, Text } = Typography;

export default function UUIDGenerator() {
  const [newUUID] = useState<string>(uuidv4());
  const [bulkValue, setBulkValue] = useState<number | null>();
  const [bulkUUIDs, setBulkUUIDs] = useState<string | undefined>(undefined);
  const [bulkIsLoading, setBulkIsLoading] = useState<boolean>(false);

  const downloadFile = (uuids: string) => {
    setBulkIsLoading(true);
    const link = document.createElement("a");
    const file = new Blob([uuids], { type: "text/plain" });
    link.href = URL.createObjectURL(file);
    link.download = "utils4dev_uuid.txt";
    link.click();
    URL.revokeObjectURL(link.href);
    setBulkIsLoading(false);
  };

  const generateBulkUUIDs = (isDownloadFile = false) => {
    setBulkIsLoading(true);
    if (!bulkValue) return;
    let bulkUUIDs = "";
    for (let i = 0; i < bulkValue; i++) {
      bulkUUIDs += `${uuidv4()} \n`;
    }
    if (isDownloadFile) {
      downloadFile(bulkUUIDs);
    } else {
      setBulkUUIDs(bulkUUIDs);
    }

    setBulkIsLoading(false);
  };

  return (
    <Flex gap="large" vertical>
      <Card title="UUID Generator" bordered={false}>
        <Flex gap="middle" align="center" vertical>
          <Flex wrap gap="middle" align="center">
            <Title level={2} copyable={{ text: newUUID }}>
              {newUUID}
            </Title>
            <Tooltip title="Reload">
              <Button
                type="primary"
                shape="circle"
                icon={<ReloadOutlined />}
                onClick={() => window.location.reload()}
              />
            </Tooltip>
          </Flex>
        </Flex>
      </Card>
      <Card title="Bulk UUID Generator" bordered={false}>
        <Flex gap="middle" vertical>
          <Flex gap="middle" justify="center">
            <InputNumber
              min={1}
              max={50000}
              placeholder="Max 50k"
              value={bulkValue}
              onChange={setBulkValue}
              style={{ width: "20%" }}
              onPressEnter={() => generateBulkUUIDs(false)}
            />
            <Button
              type="primary"
              onClick={() => generateBulkUUIDs(false)}
              disabled={!bulkValue}
              loading={bulkIsLoading}
            >
              Generate
            </Button>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              disabled={!bulkValue}
              loading={bulkIsLoading}
              onClick={() => generateBulkUUIDs(true)}
            />
          </Flex>
          {bulkUUIDs && (
            <Flex gap="middle" justify="center">
              <Paragraph copyable={{ text: bulkUUIDs }} />
              <Paragraph
                ellipsis={{ rows: 10, expandable: true }}
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                {bulkUUIDs}
              </Paragraph>
            </Flex>
          )}
        </Flex>
      </Card>
      <Card title="What is a UUID?" bordered={false}>
        <Paragraph>
          A UUID (Universally Unique Identifier) is a 128-bit identifier used to
          uniquely identify information across different systems without
          requiring a central registry. This ensures that every UUID generated
          is unique, making it an ideal tool for assigning unique keys in
          distributed systems, databases, and software applications.
        </Paragraph>

        <Paragraph>
          <Text strong>Key features of UUIDs include:</Text>
        </Paragraph>

        <Paragraph>
          <ul>
            <li>
              <Text strong>Universality:</Text> UUIDs are designed to be
              globally unique.
            </li>
            <li>
              <Text strong>Versatility:</Text> They can be used in various
              contexts such as database keys, device identifiers, and more.
            </li>
            <li>
              <Text strong>Collision Resistance:</Text> The likelihood of two
              UUIDs being the same is extremely low, even when generated
              independently by different systems.
            </li>
          </ul>
        </Paragraph>
        <Paragraph>
          UUIDs are represented as 32 hexadecimal characters, divided into five
          groups separated by hyphens, for example:
          <Text code>123e4567-e89b-12d3-a456-426614174000</Text>.
        </Paragraph>
      </Card>
      <>
        <Adsense
          client="ca-pub-7085157515750322"
          slot="2188863532"
          style={{ display: "block" }}
          layout="in-article"
          format="fluid"
        />
      </>
    </Flex>
  );
}
